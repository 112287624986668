import { inject } from '@angular/core';
import { CanMatchFn, Data, Route, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { UserRole } from '../enums/user-role';
import { UserProfile } from '../models/user-profile';
import { CurrentOrganizationService } from '../services/current-organization.service';
import { UserAuthService } from '../services/user-auth.service';
import { routePaths } from '../utils/route-paths';

/**
 * Guard that checks user role.
 * Available roles should be in data.userTypes field. If none userRoles specified, no users can enter except Global Admin.
 * @example
 * {
 *   path: 'properties',
 *   canMatch: [restrictUserRoleGuard()],
 *   data: {
 *     userRoles: [UserRole.Engineer, UserRole.Developer],
 *   },
 *   ...
 * }
 */
export function restrictUserRoleGuard(): CanMatchFn {
  return (route: Route) => {
    const userService = inject(UserAuthService);
    const router = inject(Router);
    const currentOrganizationService = inject(CurrentOrganizationService);
    const allowedRoles = getAllowedUserRoles(route.data);
    return combineLatest([
      userService.currentUser$.pipe(first()),
      currentOrganizationService.organization$,
    ])
      .pipe(
        map(([user, organization]) => {
          const userRoles = organization?.roles ?? [];
          if (Boolean(user?.isGlobalAdmin) || userRoles.some(role => allowedRoles.includes(role))) {
            return true;
          }

          const redirectLink = getRedirectLink(user, userRoles);

          // Access denied, perform redirect.
          return router.createUrlTree(redirectLink);
        }),
      );
  };
}

/**
 * Get allowed user roles.
 * @param data Router data.
 */
function getAllowedUserRoles(data?: Data): UserRole[] {
  return data?.['userRoles'] ?? [];
}

/**
 * Get redirect link.
 * @param user User.
 * @param userRoles User roles.
 */
function getRedirectLink(user: UserProfile | null, userRoles: readonly UserRole[]): string[] {
  if (user === null) {
    return routePaths.login;
  }

  const redirectRoutesMap: Partial<Record<UserRole, string[]>> = {
    [UserRole.Developer]: routePaths.resources,
  };

  return userRoles
    .map(role => redirectRoutesMap[role])
    .filter(Boolean)
    .at(0) ?? routePaths.login;
}
